<template>
  <div class="d-flex justify-content-between">
    <div class="d-flex">
      <ion-button class="back-button" @click="back">
        <ion-icon class="icon" :icon="arrowBackOutline" />
      </ion-button>
    </div>
    <div class="mx-auto title text-black mt-2">
      {{
        $route.name === 'social-space-chatroom-trending'
          ? 'Trending'
          : $route.name === 'social-space-chatroom-top'
          ? 'Top'
          : 'Latest'
      }}
      Chatrooms
    </div>
  </div>
  <div class="mt-3">
    <ChatroomCard
      v-for="room in chatrooms"
      :key="room.id"
      :chatroom="room"
      :member-count="get(counts, `${room.id}.member-count`) || 0"
      :status="get(counts, `${room.id}.status`) || false"
      :muted="get(mutedStatus, `${room.id}.is_muted`) || false"
      :unreadchat="get(chatUnreadCounts, `${room.id}.unread_count`) || 0"
      :lastmsg="get(counts, `${room.id}.last_msg_time`) || null"
    />
    <div class="d-flex justify-content-center clickable-item-hov">
      <ChLoading size="sm" v-if="isLoading" class="spinner" />
      <p v-else v-show="paging.next" @click="nextPage">Load More</p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { arrowBackOutline } from 'ionicons/icons';
import ChatroomCard from '@/shared/components/storage/ChatroomCard.vue';
import {
  getChatroomCounts,
  getchatUnreadCounts,
  getTopChatrooms,
  getUnmuteAndMuteNotificationStatus,
} from '@/shared/actions/socialSpaceChatroom';

import { getNextPage } from '@/shared/actions/worlds';
import type { Paging } from '@/shared/types/static-types';

const router = useRouter();
const chatUnreadCounts = ref({});
const route = useRoute();
const isLoading = ref(false);
const mutedStatus = ref({});
const paging = ref({
  next: null,
  previous: null,
  count: 0,
  page: 1,
}) as Ref<{ next: string | null; previous: string | null; count: number; page: number }>;

const props = defineProps({
  sectionTitle: {
    type: String,
    required: true,
  },
  toTop: {
    type: Boolean,
    default: false,
  },
});

const chatrooms: any = ref([]);
const counts = ref({});

const fetchchatUnreadCounts = async () => {
  chatUnreadCounts.value = {};
  const resp = await getchatUnreadCounts(map(chatrooms.value, 'id') as string[]);
  chatUnreadCounts.value = keyBy(resp, 'room');
};
const fetchMutedAndUmuteStatus = async () => {
  const resp = await getUnmuteAndMuteNotificationStatus(map(chatrooms.value, 'id') as string[]);
  mutedStatus.value = keyBy(resp, 'room');
};

const fetchChatroomCountsAndStatus = async () => {
  const resp = await getChatroomCounts(map(chatrooms.value, 'id') as string[]);
  counts.value = keyBy(resp, 'id');
};

const fetchPublicChatrooms = async () => {
  let resp: any;
  isLoading.value = true;
  route.name === 'social-space-chatroom-trending'
    ? (resp = await getTopChatrooms('trending', 1))
    : route.name === 'social-space-chatroom-top'
    ? (resp = await getTopChatrooms('top', 1))
    : (resp = await getTopChatrooms('-created', 1));
  chatrooms.value = resp.results;
  paging.value = resp;
  fetchChatroomCountsAndStatus();
  fetchchatUnreadCounts();
  fetchMutedAndUmuteStatus();
  isLoading.value = false;
};
const nextPage = async () => {
  isLoading.value = true;
  const data = await getNextPage(paging.value as Paging);
  chatrooms.value = [...chatrooms.value, ...data.results];
  paging.value = data;
  isLoading.value = false;
  fetchChatroomCountsAndStatus();
  fetchchatUnreadCounts();
  fetchMutedAndUmuteStatus();
};

onMounted(async () => {
  fetchPublicChatrooms();
});

const back = () => {
  router.go(-1);
};
</script>

<style lang="sass" scoped>
.back-button
  --background: #431c89
  --border-radius: 8px

.see-all-text
  color: #214163
.chatroom-btn
  --border-radius: 15px
  height: 22px
.text
  font-size: 12px
  margin-top: 0.22rem

.social-space-chatrooms
  overflow-x: auto
.section-title
  font-size: 36px
  font-weight: bold
  margin-bottom: 12px
  margin-top: 30px
.title
  font-size: 20px
  font-weight: bold
.description
  font-size: 14px
.icon
  font-size: 16px
  font-weight: bold
.chat-room-card
  overflow: unset

  @media(min-width: 420px)
    min-width: 100% !important
    max-width: 28% !important
  @media(max-width: 419px)
    min-width: 100% !important
    max-width: 50% !important
  .img
    width: 65px !important
    height: 65px !important
    border-radius: 35px
    border: solid gray 0.1px
    object-fit: cover

.chat-rooms
  overflow-x: auto
  overflow: unset
.chat-rooms::-webkit-scrollbar
  display: none
.chat-rooms
  -ms-overflow-style: none
  scrollbar-width: none
</style>
